.icon {
  align-items: center;
  display: flex;
  justify-content: center;
  transition: color 100ms ease-in-out;

  &.color-default {
    color: var(--color-gray-6);
  }

  &.size-small {
    font-size: 1rem;
  }

  &.size-regular {
    font-size: 1.5rem;
  }

  &.size-large {
    font-size: var(--fontsize-display-1);
  }
}
