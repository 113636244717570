:root {
  --page-header-height: 4rem;

  /* font family */
  --font-family: 'Inter', sans-serif;

  /* font-sizes */
  --fontsize-small: 0.813rem;
  /* 13px */
  --fontsize-body: 0.875rem;
  /* 14px */
  --fontsize-title-1: 1.063rem;
  /* 17px */
  --fontsize-title-2: 1.313rem;
  /* 21px */
  --fontsize-title-3: 1.625rem;
  /* 26px */
  --fontsize-display-1: 2rem;
  /* 32px */
  --fontsize-display-2: 2.5rem;
  /* 40px */
  --fontsize-display-3: 3rem;
  /* 48px */

  /* font-weight */
  --font-weight-light: 300;
  --font-weight-normal: 400;
  --font-weight-medium: 500;
  --font-weight-semi-bold: 600;
  --font-weight-bold: 700;

  /* shadows */
  --shadow-1: 0 1px 1px rgba(0, 0, 0, 0.1);
  --shadow-2: 0 3px 3px rgba(0, 0, 0, 0.1);
  --shadow-3: 0 6px 6px rgba(0, 0, 0, 0.08);
  --shadow-4: 0 16px 16px rgba(0, 0, 0, 0.1);
  --shadow-5: 0 32px 40px rgba(0, 0, 0, 0.1);

  /* custom colors */
  --color-veo-green: var(--color-green-500);
  --color-digital-primary: var(--color-green-700);
  --color-error: var(--color-red-600);
  --color-blue: #1482FF;
  --color-white: #FFF;
  --color-black: #121212;
  --color-background-main: #E5E5E5;
  --color-warning-yellow: #FFF9C0;

  /* red shades */
  --color-red-50: #FFEBEE;
  --color-red-100: #FFCDD3;
  --color-red-200: #F59A9A;
  --color-red-300: #ED7273;
  --color-red-400: #F84F4F;
  --color-red-500: #FE3D33;
  --color-red-600: #EF3232;
  --color-red-700: #DD272C;
  --color-red-800: #D01E25;
  --color-red-900: #C10918;

  /* blue shades */
  --color-blue-50: #E2F2FF;
  --color-blue-100: #BADEFF;
  --color-blue-200: #8BCAFF;
  --color-blue-300: #54B4FF;
  --color-blue-400: #22A3FF;
  --color-blue-500: #0091FF;
  --color-blue-600: #1482FF;
  --color-blue-700: #266EFF;
  --color-blue-800: #2E5BEC;
  --color-blue-900: #3636CC;

  /* gray shades */
  --color-gray-1: #FAFAFA;
  --color-gray-2: #F5F5F5;
  --color-gray-3: #EFEFEF;
  --color-gray-4: #E6E6E6;
  --color-gray-5: #DADADA;
  --color-gray-6: #BDBDBD;
  --color-gray-7: #969696;
  --color-gray-8: #7D7D7D;
  --color-gray-9: #575757;
  --color-gray-10: #404040;
  --color-gray-11: #262626;
  --color-gray-12: #1A1A1A;

  /* green colors */
  --color-green-50: #E8F8E9;
  --color-green-100: #C7ECC8;
  --color-green-200: #A3E0A5;
  --color-green-300: #7BD57F;
  --color-green-400: #5ACB62;
  --color-green-500: #35C144;
  --color-green-600: #2AB13A;
  --color-green-700: #199F2E;
  --color-green-800: #008D22;
  --color-green-900: #006E06;

  /* border colors */
  --color-border: #CCC;
  --color-border-gray: #E7E7EC;
  --color-line: #CCC;

  /* text colors */
  --color-text-primary: #2E3033;

  /* border-radius */
  --border-radius: 0.25rem;
  --border-radius-card: calc(var(--border-radius) * 2);
  /*  .5rem */
  --border-radius-modals: calc(var(--border-radius) * 2);
  /*  .5rem */
}

/* custom styles */

* {
  box-sizing: border-box;
}

/* body */
body {
  background: var(--color-main-background);
  font-family: var(--font-family);
  line-height: 1.5;
  margin: 0;
  min-height: 100vh;
}

/* heading */
h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--color-text-primary);
  font-weight: var(--font-weight-semi-bold);
  margin: 0;
}

h1 {
  font-size: 2.375rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.75rem;
}

h4 {
  font-size: 1.5rem;
}

h5 {
  font-size: 1.063rem;
}

h6 {
  font-size: 1rem;
}

/* media queries */

@media (max-width: 767px) {
  h1 {
    font-size: 1.5rem;
  }

  h2 {
    font-size: 1.3rem;
  }

  h4 {
    font-size: 0.875rem;
  }

  h5 {
    font-size: 0.758rem;
  }
}
