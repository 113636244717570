.links {
  display: flex;

  .navigate {
    color: var(--color-gray-7);
    font-size: 1.313rem;
    font-weight: var(--font-weight-semi-bold);
    margin-right: 2rem;
    text-decoration: none;

    @media (max-width: 767px) {
      font-size: var(--fontsize-body);
    }
  }
}

.active {
  color: var(--color-gray-12);
  font-size: 1.313rem;
  font-weight: var(--font-weight-semi-bold);
  margin-right: 2rem;
  padding-bottom: 1rem;
  text-decoration: none;

  @media (max-width: 767px) {
    border-bottom: 1px solid var(--color-black);
    font-size: var(--fontsize-body);
    margin-bottom: -0.05rem;
  }
}
