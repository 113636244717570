.dropdown-container {
  position: relative;
  width: initial;

  .input-hidden {
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  .input-hidden:focus {
    border: 1px solid var(--color-blue);
    border-radius: 0.125rem;
    box-shadow: 0 0 0 4px rgba(57, 136, 255, 0.2);
    height: 90%;
    left: 0;
    opacity: 100;
    outline: none !important;
    padding-left: 0.75rem;
    position: absolute;
    top: 0;
    width: 100%;
  }

  .dropdown-select {
    align-items: center;
    background-color: var(--color-gray-2);
    border: 1px solid transparent;
    border-radius: 0.125rem;
    color: var(--color-gray-12);
    cursor: pointer;
    display: flex;
    font-size: var(--fontsize-small);
    justify-content: space-between;
    padding: 0.703rem 1rem;
    position: relative;
    text-align: left;
    width: 100%;

    .dropdown-icon-text {
      display: flex;
      flex-direction: row;
    }

    svg {
      color: var(--color-gray-8);
      margin: auto;
    }

    .dropdown-text {
      color: var(--color-gray-8);
      overflow: hidden;
      padding-left: 0.5rem;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .dropdown-chevron-icon {
    align-self: start;
    color: var(--color-gray-8);
  }

  .dropdown-list-container {
    background-color: var(--color-gray-2);
    border-radius: var(--border-radius);
    box-shadow: var(--shadow-3);
    list-style-type: none;
    padding: 0.25rem 0;
    position: absolute;
    top: 33px;
    width: 100%;
    z-index: 100;

    .dropdown-list {
      align-items: center;
      background: transparent;
      border: 0;
      color: var(--color-gray-12);
      cursor: pointer;
      display: flex;
      font-size: var(--fontsize-small);
      line-break: anywhere;
      padding: 0.625rem 1rem;
      position: relative;
      text-align: left;
      width: 100%;

      &:hover {
        background-color: var(--color-gray-4);
      }
    }
  }
}
