.success-modal-wrapper {
  display: flex;
  flex-direction: row;

  .success-modal-border {
    color: transparent;
    width: 0.25rem;
  }

  .border-success {
    background-color: var(--color-green-800);
  }

  .border-error {
    background-color: var(--color-red-800);
  }

  .border-deleted {
    background-color: var(--color-blue-800);
  }

  .success-modal {
    align-items: center;
    background-color: var(--color-white);
    border: 1px solid var(--color-gray-5);
    box-shadow: var(--shadow-3);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 1rem 1.75rem 1rem 1.625rem;
    width: 26.25rem;

    @media (max-width: 767px) {
      width: 90vw;
    }

    .content-icon-wrapper {
      align-items: center;
      display: flex;
      flex-direction: row;

      .content-wrapper {
        display: flex;
        flex-direction: column;
        gap: 0.313rem;

        .success-modal-title {
          font-size: var(--fontsize-body);
          font-weight: var(--font-weight-semi-bold);
        }

        .title-deleted {
          color: var(--color-blue-800);
        }

        .title-error {
          color: var(--color-red-800);
        }

        .title-success {
          color: var(--color-green-800);
        }
      }

      .success-message {
        font-size: 0.625rem;
      }

      .success-icon {
        color: var(--color-green-800);
        padding-right: 1.125rem;
      }

      .delete-icon {
        color: var(--color-blue-800);
        padding-right: 1.125rem;
      }

      .error-icon {
        color: var(--color-red-800);
        padding-right: 1.125rem;
      }
    }

    .icon-button {
      background-color: transparent;
      border: 0;
      color: var(--color-gray-6);
      cursor: pointer;
      margin-left: auto;
    }
  }
}
