.modal-content {
  box-sizing: content-box;
  padding: 2rem 4rem;
  width: 23.438rem;

  @media (max-width: 767px) {
    margin: 0 2rem;
    padding: 2.75rem 0;
    width: 18rem;
  }

  .modal-submit {
    display: flex;
    justify-content: end;
    padding-top: 2rem;
  }
}
