.icon-button {
  background: none;
  border: 0;
  cursor: pointer;
  padding: 0.25rem;

  .icon.color-default:hover {
    color: var(--color-black);
  }
}
