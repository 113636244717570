.action-button {
  align-items: center;
  background: transparent;
  border: 0;
  border-radius: 0.5rem;
  color: var(--color-black);
  cursor: pointer;
  display: flex;
  font-family: var(--font-family);
  font-size: var(--fontsize-small);
  padding: 0.625rem 1rem 0.625rem 0.875rem;
  white-space: nowrap;
  width: 100%;

  &:hover,
  &:focus,
  &:active {
    background-color: var(--color-gray-2);
  }

  svg {
    margin-right: 0.5rem;
  }
}
