.layout {
  background: var(--color-gray-2);
  min-height: 100vh;
  padding-bottom: var(--page-header-height);

  .content {
    padding: var(--page-header-height) 6.25rem;
  }

  @media (max-width: 767px) {
    .content {
      margin-left: 0;
      margin-right: 0;
      margin-top: 1.688rem;
    }
  }
}
