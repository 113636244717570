.uploading-bar-outer {
  filter: drop-shadow(var(--shadow-3));

  .uploading-bar-inner {
    background-color: var(--color-white);
    border-radius: var(--border-radius);
    display: flex;
    flex-direction: column;
    margin: 2rem 0;
    padding: 0.9375rem 2.75rem 1.375rem 2.75rem;

    .progress-bar-content {
      display: flex;
      flex-direction: row;
      font-size: var(--fontsize-small);
      justify-content: space-between;
      padding-bottom: 0.563rem;
    }

    .progress-bar {
      background-color: var(--color-background-main);
      border-radius: 0.1875rem;
      display: inline-block;
      min-height: 0.4rem;
      position: relative;
      width: 100%;
    }

    .progress-bar-loaded {
      background-color: var(--color-veo-green);
      border-radius: 0.1875rem;
      display: inline-block;
      left: 0;
      min-height: 0.4rem;
      position: absolute;
      top: 0;
    }
  }

  .weight-600 {
    font-weight: var(--font-weight-semi-bold);
  }
}
