.error-page {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 26.063rem;
  justify-content: space-between;
  left: 50%;
  margin-top: 15.438rem;
  position: absolute;
  text-align: center;
  top: 15.438rem;
  transform: translate(-50%, -50%);
  width: 39.125rem;

  @media (max-width: 667px) {
    padding: 0 1rem;
    width: 100%;
  }

  .veo-logo-dark {
    color: var(--color-black);

    @media (max-width: 667px) {
      padding-bottom: 1rem;
    }
  }

  &-title {
    font-size: var(--fontsize-display-1);
    font-weight: var(--font-weight-semi-bold);
    line-height: 120%;
    width: 80%;
  }

  &-text {
    @media (max-width: 667px) {
      padding: 1rem 0;
    }
  }

  &-line {
    background-color: var(--color-gray-4);
    border: 1px solid var(--color-gray-4);
    margin-top: 2rem;
    width: 39.125rem;

    @media (max-width: 667px) {
      width: 100%;
    }
  }
}
