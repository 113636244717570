.modal-wrapper {
  align-items: center;
  background-color: rgba(18, 18, 18, 0.7);
  bottom: 0;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 100;

  &-responsive {
    background: var(--color-white);
    border-radius: var(--border-radius-modals);
    box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.25);
  }

  &-cover-the-page {
    background: var(--color-white);
    border-radius: var(--border-radius-modals);
    box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.25);

    @media (max-width: 767px) {
      border-radius: 0;
      height: 100vh;
      width: 100%;
    }
  }
}
