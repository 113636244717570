.row {
  column-gap: 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr min-content min-content;
  row-gap: 1rem;
}

.action {
  display: flex;
}
