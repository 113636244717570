.options-menu-container {
  background-color: var(--color-gray-3);
  border-radius: var(--border-radius);
  list-style: none;
  min-width: 10rem;
  padding: 0.25rem 0;
  position: absolute;
  right: 1.1rem;
  top: 1.8rem;
  z-index: 100;

  .options-menu-button {
    align-items: center;
    background: transparent;
    border: 0;
    color: var(--color-gray-11);
    cursor: pointer;
    display: flex;
    font-family: var(--font-family);
    font-size: var(--fontsize-small);
    min-width: 10rem;
    padding: 0.625rem 1rem;

    &:active,
    &:focus,
    &:hover {
      background-color: var(--color-gray-4);
    }
  }
}
