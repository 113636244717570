.button {
  border: 0;
  border-radius: var(--border-radius);
  box-shadow: 0 2px 2px rgb(0 0 0 / 10%);
  color: var(--color-white);
  cursor: pointer;
  font-family: var(--font-family);
  font-size: var(--fontsize-body);
  font-weight: var(--font-weight-medium);
  padding: 0.625rem 1.5rem;
  white-space: nowrap;

  &-confirm {
    background-color: var(--color-veo-green);

    &:hover {
      background-color: var(--color-digital-primary);
    }
  }

  &-delete {
    background-color: var(--color-error);

    &:hover {
      background-color: var(--color-red-800);
    }
  }

  &-delete-outlined {
    background: transparent;
    border: 1px solid var(--color-error);
    box-shadow: none;
    color: var(--color-error);

    &:hover {
      background-color: var(--color-red-800);
      color: var(--color-white);
    }
  }

  &-disabled {
    background-color: var(--color-gray-4);
    box-shadow: none;
    color: var(--color-gray-7);
  }

  &-cancel {
    background: transparent;
    box-shadow: none;
    color: var(--color-text-primary);
  }

  &-backoffice {
    background-color: var(--color-gray-6);
    color: var(--color-white);
  }

  &:disabled {
    background-color: var(--color-gray-5);
  }

  &-dark {
    background-color: var(--color-black);
    color: var(--color-white);
  }
}
