.members-list {
  border-bottom: 1px solid var(--color-gray-4);
  color: var(--color-gray-11);
  font-size: var(--fontsize-body);
  padding: 1.25rem 0;

  &:hover,
  &.selected {
    background-color: var(--color-gray-2);
  }

  &-disabled {
    color: var(--color-gray-6) !important;
  }

  &-row {
    align-items: center;
    column-gap: 0.7rem;
    display: grid;
    grid-template-columns: repeat(4, 1fr) 70px 30px;
    margin: 0 1rem;

    &-name {
      white-space: nowrap;
    }

    &-email,
    &-team {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &-pending {
      &-tooltip {
        display: none;
      }

      &:hover {
        cursor: pointer;

        .members-list-row-pending-tooltip {
          display: block;
        }
      }
    }
  }
}

@media (max-width: 867px) {
  .members-list {
    margin: 0 1rem;
    padding: 0.75rem 0;

    &-row {
      column-gap: 0;
      grid-template-columns: 112px repeat(4, 1fr);

      &-email {
        color: var(--color-gray-8);
        grid-column: 1 / 6;
        grid-row: 2 / 3;
      }

      &-name {
        white-space: nowrap;
      }

      &-team {
        color: var(--color-gray-8);
        grid-column: 1;
        grid-row: 3 / 3;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      &-role {
        color: var(--color-gray-8);
        grid-column: 2;
        grid-row: 3 / 3;

        &::before {
          content: '|';
          padding-right: 0.7rem;
        }
      }

      &-pending {
        align-self: center;
        grid-column: 6;
        grid-row: 2 / 3;
      }

      &-options {
        align-self: end;
        grid-column: 6;
        grid-row: 2 / 3;
      }
    }
  }
}
