.roles-tooltip {
  background-color: var(--color-white);
  border-radius: var(--border-radius);
  box-shadow: var(--shadow-2);
  display: inline-flex;
  flex-direction: column;
  gap: 0.75rem;
  justify-content: space-between;
  padding: 0.75rem;
  position: absolute;
  width: 15.563rem;
  z-index: 999;

  &-content {
    font-size: 0.625rem;

    &-title {
      font-weight: var(--font-weight-semi-bold);
      margin: 0;
      padding: 0;
    }

    &-paragraph {
      color: var(--color-gray-8);
      font-weight: var(--font-weight-normal);
      margin: 0;
      padding: 0;
    }
  }
}
