.header {
  align-items: center;
  display: flex;

  .heading {
    flex: 0;
    white-space: nowrap;
  }

  .actions {
    display: flex;
    flex: 1;
    justify-content: end;

    div {
      margin-left: 0.5rem;
    }

    div:first-child {
      margin-left: 0;
    }
  }
}

.league-title {
  margin-left: 2.5rem;
  padding-top: 2.5rem;
}

.league-container {
  background-color: var(--color-white);
  margin-top: 1.875rem;
  padding-bottom: 1rem;
}

.league-main-header {
  display: flex;
  flex-direction: row;
  padding: 2rem 0 1rem 2.5rem;

  .empty-label-crest {
    width: 2.75rem;
  }

  .league-name-label {
    font-weight: var(--font-weight-semi-bold);
    width: 35rem;
  }

  .empty-label {
    width: 4.508rem;
  }
}

.legaue-info {
  align-items: center;
  display: flex;
  flex-direction: row;
  padding: 0 6.5rem 0 2.5rem;

  .legaue-name-data {
    margin-right: auto;
    padding-left: 1rem;
    width: 35rem;
    word-break: break-all;
  }
}
