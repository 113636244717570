@font-face {
  font-display: swap;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  src:
    url('./Inter-Regular.woff2')format('woff2'),
    url('./Inter-Regular.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'Inter';
  font-style: medium;
  font-weight: 500;
  src:
    url('./Inter-Medium.woff2') format('woff2'),
    url('./Inter-Medium.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'Inter';
  font-style: semi-bold;
  font-weight: 600;
  src:
    url('./Inter-SemiBold.woff2') format('woff2'),
    url('./Inter-SemiBold.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'Inter';
  font-style: bold;
  font-weight: 700;
  src:
    url('./Inter-Bold.woff2') format('woff2'),
    url('./Inter-Bold.woff') format('woff');
}

* { -webkit-font-smoothing: antialiased; }

html,
body {
  font-family: 'Inter', sans-serif;
}
