.list-wrapper {
  align-items: center;
  background-color: var(--color-gray-3);
  color: var(--color-gray-12);
  column-gap: 0.7rem;
  display: grid;
  font-weight: var(--font-weight-semi-bold);
  grid-template-columns: repeat(4, 1fr) 70px 30px;
  height: 3.0625rem;
  padding: 0 1rem;
  white-space: nowrap;

  @media (max-width: 867px) {
    display: none;
  }
}
