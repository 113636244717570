@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.spinner {
  animation: spin 0.75s linear infinite;
  border-color: var(--color-digital-primary);
  border-radius: 50%;
  border-right-color: transparent !important;
  border-style: solid;
  border-width: 0.313rem;
  box-sizing: content-box;
  display: block;
  height: 0.75rem;
  margin: 1rem auto;
  transform: translate(-50%, -50%);
  width: 0.75rem;
}

.float {
  display: inline-block;
  left: 50%;
  margin: 0;
  position: absolute;
  top: 50%;
  vertical-align: text-bottom;
}
