.member {
  padding: 2.75rem 6rem;

  @media (max-width: 767px) {
    margin: 0 2rem;
    padding: 2.75rem 0;
  }

  &-invite {
    margin-bottom: 1rem;
  }

  &-form {
    display: flex;
    flex-direction: column;
    width: 23.438rem;

    @media (max-width: 767px) {
      width: 100%;
    }

    &-titles {
      color: var(--color-black);
      display: flex;
      font-size: var(--fontsize-small);
      font-weight: var(--font-weight-semi-bold);
      margin-top: 1rem;
    }

    &-input {
      background-color: var(--color-gray-2);
      border: 0;
      color: var(--color-gray-6);
      display: block;
      height: 2.5rem;
      width: 100%;
    }

    &-icon {
      color: var(--color-gray-6);
      cursor: pointer;
      width: 1.25rem;

      &-tooltip {
        margin-left: 1rem;
        opacity: 0;
        position: relative;
        transition: opacity 0.25s ease-in;
        visibility: hidden;
      }

      svg {
        margin-left: 0.833rem;
        padding-top: 0.625rem;
        width: 0.833rem;
      }

      &:hover {
        color: var(--color-digital-primary);
        cursor: pointer;

        .member-form-icon-tooltip {
          bottom: 2rem;
          color: var(--color-gray-11);
          left: 1rem;
          opacity: 1;
          visibility: visible;
          z-index: 101;

          @media (max-width: 767px) {
            bottom: 0;
            left: -5.5rem;
          }
        }
      }
    }

    &-icon-wrapper {
      align-items: center;
      display: flex;
    }

    .footer {
      display: flex;
      flex-direction: row;
      gap: 1rem;
      justify-content: flex-end;
      margin-top: 2.625rem;
      width: 100%;
    }
  }
}
