.options-container {
  position: relative;

  .options-button {
    background: transparent;
    border: 0;
    cursor: pointer;

    &:disabled {
      display: none;
    }
  }
}
