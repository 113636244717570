.modal-message {
  font-size: var(--fontsize-body);
  font-weight: var(--font-weight-normal);
  margin: 2rem 0 1.5rem 0;
}

.modal-data {
  font-size: var(--fontsize-body);
  font-weight: var(--font-weight-semi-bold);
}
