.modal-content {
  box-sizing: content-box;
  padding: 3rem 4rem;
  width: 23.438rem;

  @media (max-width: 767px) {
    margin: 0 2rem;
    padding: 2.75rem 0;
    width: 18rem;
  }

  &-header {
    margin-top: 0;
  }

  &-description {
    color: var(--color-black);
    font-size: var(--fontsize-body);
    margin-top: 1rem;

    >a {
      color: var(--color-black);
      font-weight: var(--font-weight-bold);
      text-decoration: underline;
    }
  }

  &-warning {
    background-color: var(--color-warning-yellow);
    border-radius: 0.125rem;
    color: var(--color-black);
    display: flex;
    flex: 1 1;
    font-size: var(--fontsize-body);
    margin-top: 1rem;
    padding-bottom: 0.3rem;
    padding-top: 0.3rem;

    &-icon {
      margin: 0.8rem;
    }

    &-text {
      margin-top: 0.5rem;
    }
  }

  .modal-submit {
    display: flex;
    justify-content: end;
    padding-top: 2rem;
  }

  &-titles {
    color: var(--color-black);
    display: flex;
    font-size: var(--fontsize-body);
    font-weight: var(--font-weight-bold);
    margin-bottom: 0.25rem;
    margin-top: 1rem;
  }

  &-video {
    display: flex;
    flex: 1 1;
    padding-top: 0.5rem;

    &-thumbnail {
      flex: 0 0 4rem;
      margin-left: 0.75rem;

      img {
        border-radius: 0.125rem;
        display: flex;
        height: 3rem;
        width: 4.5rem;
      }
    }

    &-match {
      display: flex;
      flex: 1 0 0;
      flex-direction: column;
      padding-left: 1.2rem;
      padding-right: 1rem;

      &-title {
        font-size: var(--fontsize-body);
        font-weight: var(--font-weight-bold);
      }

      &-duration {
        color: var(--color-gray-8);
        font-size: var(--fontsize-body);
        font-weight: var(--font-weight-normal);
      }
    }
  }
}
