.edit {
  padding: 2.75rem 6rem;

  @media (max-width: 767px) {
    margin: 0 2rem;
    padding: 2.75rem 0;
  }

  &-title {
    margin-bottom: 1rem;
  }

  &-form {
    display: flex;
    flex-direction: column;
    width: 23.438rem;

    @media (max-width: 767px) {
      width: 100%;
    }

    &-width {
      @media (min-width: 767px) {
        width: 10rem;
      }
    }

    &-titles {
      color: var(--color-black);
      display: flex;
      font-size: var(--fontsize-small);
      font-weight: var(--font-weight-semi-bold);
      margin-top: 1rem;
    }

    &-dropdown {
      box-sizing: content-box;
      width: 100%;
    }

    &-input {
      background-color: var(--color-gray-2);
      border: 0;
      height: 2.5rem;
      width: 100%;
    }

    &-home-away-wrapper {
      display: flex;
      flex-direction: row;
      gap: 0.1rem;
      justify-content: space-between;

      @media (max-width: 767px) {
        flex-direction: column;
      }
    }

    .edit-score {
      align-items: center;
      display: flex;
      flex-direction: row;
      height: 2.5rem;
      justify-content: center;
      margin-top: 1rem;
      width: 100%;

      &-teams {
        color: var(--color-gray-11);
        font-size: 0.75rem;
        font-weight: var(--font-weight-semi-bold);
        padding: 1rem;
      }

      &-content {
        align-items: center;
        background-color: var(--color-gray-2);
        display: flex;
        flex-direction: row;
        margin: 0 1.25rem;

        &-input {
          background-color: var(--color-gray-2);
          border: 0;
          height: 2.5rem;
          text-align: center;
          width: 3rem;
        }
      }
    }

    .edit-date-container {
      .edit-date-input {
        background-color: var(--color-gray-2);
        border: 0;
        cursor: default;
        height: 2.5rem;
        min-width: 12.5rem;
        padding: 0 1rem;
        width: 100%;

        &[type='date']::before {
          color: var(--color-gray-12);
          content: attr(placeholder);
          font-family: var(--font-family);
        }

        &[type='date'] {
          color: transparent;
        }

        &[type='date']:focus,
        &[type='date']:valid {
          color: var(--color-gray-12);
        }

        &[type='date']:focus::before,
        &[type='date']:valid::before {
          content: '' !important;
        }
      }
    }

    .footer {
      display: flex;
      flex-direction: row;
      gap: 1rem;
      justify-content: flex-end;
      margin-top: 2.625rem;
      width: 100%;
    }
  }
}
