/* stylelint-disable selector-max-compound-selectors */
.upload-layout {
  display: flex;
  flex-direction: column;

  .title {
    align-items: center;
    display: flex;
    padding-left: 2.5rem;

    img {
      width: 2rem;
    }

    .league-name {
      padding-left: 1rem;
      white-space: nowrap;
    }
  }

  .uploading-table {
    background-color: var(--color-white);
    border-radius: var(--border-radius);
    padding: 2.75rem;

    .row {
      align-items: center;
      box-shadow: inset 0 -1px 0 #EEE;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 2.375rem;

      @media (max-width: 1172px) {
        flex-direction: column;
        justify-content: center;
      }

      @media (max-width: 400px) {
        display: block;
      }

      .col {
        display: flex;
        flex-direction: column;

        .center {
          margin: auto;

          .date-input {
            background-color: var(--color-gray-2);
            border: 0;
            height: 2.5rem;
            min-width: 12.5rem;
            padding: 0 1rem;

            &[type='date']::before {
              color: var(--color-gray-12);
              content: attr(placeholder);
              font-family: var(--font-family);
            }

            &[type='date'] {
              color: transparent;
            }

            &[type='date']:focus,
            &[type='date']:valid {
              color: var(--color-gray-12);
            }

            &[type='date']:focus::before,
            &[type='date']:valid::before {
              content: '' !important;
            }
          }
        }

        .label {
          align-self: center;
          color: var(--color-black);
          display: flex;
          font-weight: var(--font-weight-semi-bold);
        }

        .upload-dropdown {
          padding: 0.5rem 0 1.5rem 0;
          width: 12.5rem;

          @media (max-width: 1172px) {
            padding-bottom: 2.5rem;
          }

          @media (max-width: 400px) {
            width: 100%;
          }
        }

        .upload-content {
          align-items: center;
          padding: 0.5rem 0 1.5rem 0;

          @media (max-width: 1172px) {
            padding-bottom: 2.5rem;

            @media (max-width: 400px) {
              display: block;
            }
          }

          .score {
            align-items: center;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin: auto;

            .home {
              color: var(--color-gray-11);
              font-weight: var(--font-weight-semi-bold);
              margin-right: 1.5rem;
            }

            .score-content {
              align-items: center;
              background-color: var(--color-gray-2);
              display: flex;
              flex-direction: row;
              height: 2.5rem;
              justify-content: center;
              min-width: 6.5rem;

              .score-input {
                text-align: left;
                width: 1.125rem;

                input {
                  all: unset;
                }

                input[type='number']::-webkit-outer-spin-button,
                input[type='number']::-webkit-inner-spin-button {
                  appearance: none;
                  margin: 0;
                }

                input[type='number'] {
                  appearance: textfield;
                }

                .input {
                  overflow: hidden;
                  white-space: nowrap;
                  width: 1.125rem;
                }

                .right {
                  margin-left: 0.75rem;
                }
              }

              .separator {
                color: var(--color-gray-8);
                text-align: center;
              }
            }

            .away {
              color: var(--color-gray-11);
              font-weight: var(--font-weight-semi-bold);
              margin-left: 1.5rem;
            }
          }
        }
      }
    }

    .footer {
      display: flex;
      flex-direction: row;
      justify-content: right;
      margin-top: 2.5rem;
    }
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  appearance: none;
  margin: 0;
}

@media (max-width: 1172px) {
  h1 {
    text-align: center;
  }
}

.modal-message {
  font-size: var(--fontsize-body);
  font-weight: var(--font-weight-normal);
  margin: 2rem 0 1.5rem 0;
}

.modal-data {
  font-size: var(--fontsize-body);
  font-weight: var(--font-weight-semi-bold);
}
