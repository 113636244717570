.container {
  margin-left: 1.875rem;
  position: relative;
  width: fit-content;

  @media (max-width: 767px) {
    margin-left: 0;
    width: 100%;
  }

  .select {
    align-items: center;
    background-color: var(--color-gray-2);
    border: 0;
    border-radius: var(--border-radius);
    color: var(--color-gray-12);
    cursor: pointer;
    display: flex;
    font-family: var(--font-family);
    font-size: var(--fontsize-title-3);
    font-weight: var(--font-weight-semi-bold);
    padding: 0 0.3rem 0 1rem;
    width: 100%;

    @media (max-width: 767px) {
      font-size: 1.125rem;
      padding: 0 1.458rem;
    }
  }

  .crest-img {
    margin-right: 1rem;
    max-height: 2rem;

    img {
      height: 2rem;
      width: 2rem;
    }
  }

  .chevron-icon {
    align-self: center;
    display: flex;
    padding-left: 1rem;

    &:disabled {
      display: none;
    }
  }

  .list-container {
    background-color: var(--color-white);
    border: 1px soild;
    border-radius: var(--border-radius);
    box-shadow: var(--shadow-3);
    font-size: var(--fontsize-small);
    list-style-type: none;
    padding: 0;
    position: absolute;
    top: 2.2rem;
    width: 100%;
    z-index: 102;

    .list {
      align-items: center;
      background: transparent;
      border: 0;
      color: var(--color-gray-12);
      cursor: pointer;
      display: flex;
      font-family: var(--font-family);
      font-size: var(--fontsize-small);
      padding: 0.425rem 1rem;
      text-align: left;
      width: 100%;
      word-break: break-all;

      &:hover {
        background-color: var(--color-gray-3);
        border-radius: var(--border-radius);
      }
    }
  }
}
