.chip-container {
  background-color: var(--color-gray-4);
  border-radius: var(--border-radius);
  display: inline-block;
  font-size: 0.625rem;
  font-weight: var(--font-weight-semi-bold);
  padding: 0.25rem;
  position: relative;
  text-transform: uppercase;
  width: fit-content;

  &.green {
    background-color: var(--color-green-50);
    color: var(--color-digital-primary);
  }
}
