.delete-warning {
  color: var(--color-error);
}

.saving {
  text-align: right;
}

.header {
  align-items: center;
  display: flex;
  padding-bottom: 2rem;

  .league-crest,
  .heading {
    flex: 0;
  }

  .league-crest {
    margin-bottom: -2rem;

    label,
    svg {
      cursor: pointer;
    }

    img,
    svg {
      height: 89px;
      width: 92px;
    }

    input {
      display: none;
    }
  }

  .heading {
    white-space: nowrap;
  }

  .actions {
    display: flex;
    flex: 1;
    justify-content: end;

    div {
      margin-left: 0.5rem;
    }

    div:first-child {
      margin-left: 0;
    }

    .separator {
      border: 0;
      border-left: 1px solid var(--color-gray-7);
      margin: 0.25rem 1rem 0.25rem 1.5rem;
    }
  }
}

.section {
  margin-bottom: 2rem;

  &:last-child {
    margin-bottom: 0;
  }

  &-header {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
  }

  &-heading {
    font-size: var(--fontsize-title-2);
    margin: 0 0 1rem 0;
  }
}

.add-member {
  margin-top: 1rem;
}

.add-team {
  text-align: center;
}
