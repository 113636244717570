.video-modal-wrapper {
  background-color: var(--color-gray-2);
  bottom: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 100;

  .video-nav-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0 1rem;

    @media (max-width: 767px) {
      padding-top: 1rem;
    }

    .logo-data-wrapper {
      align-items: center;
      display: flex;
      flex-direction: row;

      .logo-button {
        background-color: transparent;
        border: 0;
        color: var(--color-digital-primary);
        cursor: pointer;
      }

      .title-date {
        padding-left: 1rem;

        .video-title-container {
          font-size: var(--fontsize-small);
          font-weight: var(--font-weight-medium);
          margin: 0;

          @media (max-width: 767px) {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 6rem;
          }
        }

        .video-date-container {
          color: var(--color-gray-8);
          font-size: 0.625rem;
          font-weight: var(--font-weight-medium);
        }
      }
    }

    .team-score {
      align-items: center;
      display: flex;
      margin-right: 7.25rem;

      @media (max-width: 767px) {
        justify-content: center;
        margin: 0;
        order: 1;
        width: 100%;
      }

      .team-title {
        font-size: 0.5rem;
        font-weight: var(--font-weight-semi-bold);
        margin-right: 1rem;
      }

      .score-data {
        font-size: var(--fontsize-title-3);
        font-weight: var(--font-weight-semi-bold);
        margin-right: 1rem;
      }
    }

    .icon-wrapper {
      align-items: center;
      display: flex;

      .icon-button {
        background-color: transparent;
        border: 0;
        cursor: pointer;
        margin-left: 0.7rem;
      }
    }
  }

  .video-wrapper {
    background-color: var(--color-black);
    border-radius: 0.5rem;
    flex: 1;
    margin: 0 1rem 1rem 1rem;
    max-height: calc(100vh - 7rem);

    @media (max-width: 767px) {
      margin: 0 0.7rem 0.7rem 0.7rem;
    }

    .video {
      border-radius: 0.5rem;
      display: block;
      height: 100%;
      object-fit: contain;
      width: 100%;
    }
  }
}
