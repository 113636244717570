.row {
  column-gap: 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 0.5fr max-content max-content;
  row-gap: 1rem;
}

.label {
  color: var(--color-black);
  font-size: var(--fontsize-small);
  font-weight: 600;
  margin-bottom: 0.5rem;
}

input {
  background-color: var(--color-gray-2);
  border: 0;
  box-sizing: border-box;
  height: 2.5rem;
  padding: 0 1rem;
  width: 100%;

  &:disabled {
    color: var(--color-gray-6);
  }
}

.error-feedback {
  color: var(--color-error);
  font-size: 0.813rem;
  font-weight: 400;
}

.status {
  padding: 0.375rem 0;
}

.action {
  padding: 0.1875rem 0;
}

.subheading {
  font-size: var(--fontsize-title-2);
  font-weight: 600;
  margin-bottom: 1rem;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
