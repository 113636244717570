.members-container {
  background-color: var(--color-white);
  margin-top: 1.875rem;

  .nav-wrapper {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 2.125rem 3.25rem 1rem 3.25rem;

    @media (max-width: 867px) {
      border-bottom: 1px solid var(--color-gray-4);
      padding: 1rem 1rem 0 1.563rem;
    }

    .nav {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;

      .menu-header {
        position: relative;

        .toggle {
          display: none;
        }

        .toggle:checked + .button-wrapper {
          display: block;
          z-index: 1;
        }

        .button-wrapper {
          @media (max-width: 867px) {
            background-color: var(--color-white);
            border: 1px solid var(--color-gray-3);
            border-radius: 8px;
            box-shadow: var(--shadow-3);
            display: none;
            position: absolute;
            right: 23%;
            top: 103%;
            width: 14.25rem;
            z-index: 101;
          }

          .action-buttons {
            display: flex;
            gap: 0.5rem;

            @media (max-width: 867px) {
              flex-direction: column;
            }
          }
        }

        .options-horizontal-icon {
          display: none;

          @media (max-width: 867px) {
            background: transparent;
            border: 0;
            color: var(--color-black);
            display: block;
            position: relative;

            &:hover {
              color: var(--color-digital-primary);
            }
          }

          svg {
            cursor: pointer;
          }
        }
      }
    }
  }

  .main-container {
    margin: 0 3.25rem 3.75rem 3.25rem;
    padding-bottom: 1.875rem;

    @media (max-width: 867px) {
      margin: 0;
    }
  }
}
