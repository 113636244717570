.recording-list {
  align-items: center;
  border-bottom: 1px solid var(--color-gray-4);
  display: flex;
  padding: 1.25rem 0;

  &:hover,
  &.selected {
    background-color: var(--color-gray-2);
  }

  &-empty {
    padding: 5rem 0;
    text-align: center;
  }

  &-clickable {
    align-items: center;
    color: var(--color-gray-11);
    cursor: pointer;
    display: flex;
    flex: 1 1;
    font-size: var(--fontsize-body);
    font-weight: var(--font-weight-medium);
    line-height: 1.5rem;
    text-decoration: none;

    &-thumbnail {
      flex: 0 0 4rem;
      margin-left: 0.75rem;

      img {
        border-radius: 0.125rem;
        display: flex;
        height: 3rem;
        width: 4.5rem;
      }
    }

    &-data {
      display: flex;
      flex: 1 1;
      padding-left: 1.5rem;

      &-match {
        display: flex;
        flex: 1 0 0;
        flex-direction: column;
        padding-right: 1rem;

        &-duration {
          color: var(--color-gray-8);
        }
      }

      &-team {
        align-items: center;
        display: flex;
        flex: 1 0 13.4rem;
        padding-left: 1rem;

        &-score {
          color: var(--color-gray-12);
          flex: 1 0 5rem;
          font-size: var(--fontsize-small);
          font-weight: var(--font-weight-semi-bold);
        }

        &-date {
          flex: 1 0 9.6rem;
        }
      }
    }
  }

  &-options {
    display: flex;
    flex: 0 0 3rem;
    padding-left: 1rem;
  }
}

@media (max-width: 867px) {
  .recording-list {
    margin: 0 1rem;

    &-clickable {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      &-thumbnail {
        margin-left: 0;
      }

      &-data {
        flex: 0 1 24rem;
        flex-direction: column;
        padding-left: 1.5rem;

        &-match {
          flex: 0;

          &-title {
            line-height: 1.0625rem;
          }

          &-duration {
            display: none;
          }
        }

        &-team {
          color: var(--color-gray-8);
          flex: 0;
          padding-left: 0;

          &-score {
            color: var(--color-gray-8);
            flex: 0;

            &::after {
              content: '|';
              padding: 0.5rem;
            }
          }

          &-date {
            color: var(--color-gray-8);
            display: inline-block;
            flex: none;
          }
        }
      }
    }

    &-options {
      flex: 0;
      padding-left: 0;
    }
  }
}

@media (min-width: 867px) and (max-width: 967px) {
  .recording-list-clickable-data-match-title {
    word-break: break-all;
  }

  .recording-list-clickable-data-team {
    padding-left: 0;
  }
}

@media (max-width: 400px) {
  .recording-list-clickable-data-match-title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 10rem;
  }
}
