.arrow-tooltip {
  background-color: var(--color-gray-11);
  border-radius: var(--border-radius);
  bottom: 135%;
  color: var(--color-white);
  font-size: var(--fontsize-small);
  font-weight: var(--font-weight-normal);
  left: 50%;
  min-width: 12rem;
  padding: 0.5rem 0.75rem;
  position: absolute;
  text-align: center;
  text-transform: none;
  transform: translate(-50%);
  z-index: 1;

  @media (max-width: 767px) {
    bottom: -30%;
    min-height: auto;
    text-align: left;
    transform: translate(-120%);
  }

  &::after {
    border-color: var(--color-gray-11) transparent transparent transparent;
    border-style: solid;
    border-width: 0.313rem;
    content: '';
    left: 50%;
    position: absolute;
    top: 100%;
    transform: translate(-50%);

    @media (max-width: 767px) {
      border-color: transparent transparent transparent var(--color-gray-11);
      bottom: 0.8rem;
      left: 100%;
      top: auto;
      transform: translate(0%);
    }
  }
}
