.message-layout {
  margin: auto;
  position: absolute;
  right: 6rem;
  top: 6rem;
  z-index: 1000;

  @media (max-width: 767px) {
    left: 50%;
    right: revert;
    transform: translate(-50%, -50%);
  }
}
